<template>
  <div
    class="about px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <div class="flex flex-wrap">
      <div class="mx-auto text-center md:w-2/3 mb-6 md:pl-2">
        <TitleComponent
          title="Unplanned Migration of Energetics"
          size="large"
        />
        <Paragraph
          ><p>
            <img
              class="alignright size-full wp-image-12268"
              src="@/assets/Resources/drain-min.jpg"
              alt="Contaminated drain"
              width="300"
              height="225"
            />
          </p>
          <p>
            Liquid energetic materials in a fluid system may come into contact
            with various process components, including tanks, tubing / piping
            (process and drain), mixer bowls, flexible hoses, fittings, valves,
            regulators, gauges, etc. Drawing a vacuum on a liquid can promote
            migration of liquid materials into any seemingly inaccessible cavity
            such as down the threads of bolts and screws, into layered or weakly
            bonded coverings, and into seams and joints. The vapors from a
            liquid energetic material can be drawn into ducts, vacuum tubing,
            and will likely condense out-of-place.
          </p>
          <p>
            <img
              class="alignleft size-full wp-image-12268"
              src="@/assets/Resources/crystals-min.jpg"
              alt="Crystallized energetic material produced by leaking pipe"
              width="307"
              height="230"
            />
          </p>
          <p>
            Solid energetic materials may also come into contact with various
            process components, including: hoppers, chutes, grinders, mills,
            mixer bowls, tubing / piping (process and drain), ovens and dryers,
            vacuum systems, etc. Granulated or powdered materials may dust,
            invading and contaminating unsealed areas (cracks, threads, hidden
            areas).
          </p>
          <p>
            <img
              class="alignright size-full wp-image-12269"
              src="@/assets/Resources/equip1-min.jpg"
              alt="Contaminated process equipment"
              width="307"
              height="230"
            />
          </p>
          <p>
            Once out-of-place, confinement can be created for energetic
            materials by their presence in small spaces: walls, joints and
            seams, pipes and fittings, electrical boxes, ventilation duct work,
            bins and containers, cracks, threads, under washers or nut and bolt
            heads, etc. Energetic materials may enter these confined spaces as a
            powder, dust, or vapor through a variety of methods: pushed or
            washed into areas during cleaning (non-soluble), carried in liquids
            as dissolved solids (later to condense out into its original solid
            form as the solvent evaporates), or otherwise migrate into and
            through porous or permeable materials.
          </p>
          <p>
            <img
              src="@/assets/Resources/electricalbox-min.jpg"
              alt="Energetic dust accumulation in an electrical box"
              width="307"
              height="230"
              class="alignleft size-full wp-image-12270"
            />
          </p>
          <p>
            All out-of-place materials must be considered as potentially
            sensitive, mass-detonating materials (Class 1.1) until proven
            otherwise.
          </p>
          <p>
            Failure to assess and address D3 hazards correctly has resulted in
            numerous incidents in the explosives industry.
          </p>
        </Paragraph>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import Paragraph from "@/components/Paragraph";

export default {
  metaInfo: {
    title: "Unplanned Migration",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content:
          "In-process energetic materials may come into contact with various process equipment. All out-of-place materials must be considered as potentially sensitive, mass-detonating materials (Class 1.1) until proven otherwise."
      }
    ]
  },
  components: {
    TitleComponent,
    Paragraph
  }
};
</script>

<style lang="scss">
.about {
  .alignleft {
    display: inline;
    float: left;
    margin-right: 15px;
  }

  .alignright {
    display: inline;
    float: right;
    margin-left: 15px;
  }
}
</style>
